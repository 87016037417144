/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Button, Text, jsx } from 'theme-ui';
import { FiMenu, FiX } from 'react-icons/fi';

const SiteNavButton = ({ isOpen, onClick }) => {
  return (
    <Button
      sx={{
        textAlign: 'center',
        color: isOpen ? 'white' : 'black',
        cursor: 'pointer',
        zIndex: 3,
        backgroundColor: 'transparent',
        borderRadius: 0,
        p: 0,
        transition: 'color 0.3s',
        '&:focus': {
          outline: 'none',
        },
      }}
      onClick={onClick}
    >
      {isOpen ? (
        <FiX sx={{ fontSize: 5, lineHeight: 1 }} />
      ) : (
        <FiMenu sx={{ fontSize: 5, lineHeight: 1 }} />
      )}
      <Text sx={{ fontSize: [0, 1], lineHeight: 1 }}>Accueil</Text>
    </Button>
  );
};

export default SiteNavButton;
