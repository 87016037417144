/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Flex, Box, Container, jsx } from 'theme-ui';
import SiteNav from './SiteNav';
import SiteNavLink from './SiteNavLink';
import SiteNavButton from './SiteNavButton';
import Logo from './Logo';

const SiteHeader = ({ location }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [pathname, setPathname] = useState(location.pathname);
  const navBgColor = pathname.includes('blog')
    ? 'orange'
    : pathname.includes('about')
    ? 'pink'
    : 'green';

  const toggleNav = () => {
    if (navOpen) {
      setNavOpen(false);
      setPathname(location.pathname);
    } else {
      setNavOpen(true);
    }
  };

  return (
    <Box sx={{ mb: [5, 6, 8] }}>
      <Container sx={{ px: 3 }}>
        <Flex
          as="header"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            py: [2, 3],
          }}
        >
          <Link
            to="/"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              zIndex: 3,
            }}
            onClick={toggleNav}
          >
            <Logo
              sx={{
                fontSize: 5,
                color: navOpen ? 'white' : 'black',
                '&:hover': { color: navOpen ? 'white' : 'black' },
              }}
            />
          </Link>
          <SiteNavButton onClick={toggleNav} isOpen={navOpen} />
          <SiteNav backgroundColor={navBgColor} isOpen={navOpen}>
            <SiteNavLink
              to="/"
              onClick={toggleNav}
              onMouseEnter={() => setPathname('/')}
            >
              Tous les liens bien-être
            </SiteNavLink>
            <SiteNavLink
              to="/about-us/"
              onClick={toggleNav}
              onMouseEnter={() => setPathname('/about-us/')}
            >
              Qui sommes-nous&nbsp;?
            </SiteNavLink>
            <SiteNavLink
              to="/blog/"
              onClick={toggleNav}
              onMouseEnter={() => setPathname('/blog/')}
              partiallyActive
            >
              Blog
            </SiteNavLink>
          </SiteNav>
        </Flex>
      </Container>
    </Box>
  );
};

export default SiteHeader;
