import React from 'react';
import { Container, Grid } from 'theme-ui';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';
import { fadeInUp } from '../lib/animations';

const Layout = ({ children, location }) => {
  return (
    <>
      <SiteHeader location={location} />
      <Container
        sx={{ px: 3, mb: 6, animation: fadeInUp, animationDuration: '0.9s' }}
      >
        <Grid columns={[1, 2, 4, 12]} gap={[4, 5]}>
          {children}
        </Grid>
      </Container>
      <SiteFooter />
    </>
  );
};

export default Layout;
