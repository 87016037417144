/** @jsx jsx */
import { Flex, Box, Container, Styled, jsx } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import Logo from './Logo';

const SiteFooter = () => {
  const { allSanitySocialLink } = useStaticQuery(
    graphql`
      query {
        allSanitySocialLink {
          nodes {
            name
            url
          }
        }
      }
    `
  );

  return (
    <Box sx={{ backgroundColor: 'white', py: 3 }}>
      <Container sx={{ px: 3 }}>
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Logo />
          <Flex>
            {allSanitySocialLink.nodes.map(socialLink => (
              <Box ml={3} key={socialLink.name}>
                <Styled.a
                  href={socialLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {socialLink.name}
                </Styled.a>
              </Box>
            ))}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default SiteFooter;
