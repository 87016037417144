/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Link } from 'gatsby';
import { jsx } from 'theme-ui';

const SiteNavLink = ({ children, sx, ...props }) => (
  <Link
    sx={{
      variant: 'text.heading',
      textDecoration: 'none',
      color: 'white',
      mb: 5,
      fontSize: [5, 7, 8],
      '&.active': {
        color: 'black',
      },
      '&:hover': {
        color: 'black',
      },
      ...sx,
    }}
    activeClassName="active"
    {...props}
  >
    {children}
  </Link>
);

export default SiteNavLink;
