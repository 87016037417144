import { keyframes } from '@emotion/core';

export const fadeInUp = keyframes({
  from: {
    transform: 'translate3d(0, 40px, 0)',
    opacity: 0,
  },
  to: {
    transform: 'translate3d(0, 0, 0)',
    opacity: 1,
  },
});
