import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Heading } from 'theme-ui';

const Logo = ({ sx, ...rest }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Heading as="div" {...rest} sx={{ variant: 'text.heading', ...sx }}>
      {site.siteMetadata.title}
    </Heading>
  );
};

export default Logo;
