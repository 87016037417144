import React from 'react';
import { Flex } from 'theme-ui';

const SiteNav = ({ backgroundColor, children, isOpen }) => (
  <Flex
    as="nav"
    sx={{
      display: 'flex',
      position: 'fixed',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      p: [3, 4],
      pt: ['63px', '81px'],
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      transition: 'background-color 0.6s, transform 0.3s',
      backgroundColor,
      transform: `${
        isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)'
      }`,
      pointerEvents: isOpen ? 'initial' : 'none',
    }}
  >
    {children}
  </Flex>
);

export default SiteNav;
